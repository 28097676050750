import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components

import FooterWhite from 'components/Footers/FooterWhite.js';

//helper

const addCol = content => {
  return (
    <Col md="12">
      <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
      <label style={{ paddingLeft: '1rem' }}>{content}</label>
    </Col>
  );
};

const createlist = elements => elements.map(element => <li>{element}</li>);
const addPara = (title, content, liElements) => {
  return (
    <div>
      <p>
        <h4>{title}</h4>
        {content}
        {liElements && <ul>{createlist(liElements)}</ul>}
      </p>
    </div>
  );
};

function Datenschutz() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <div className="wrapper" style={{ padding: '2rem' }}>
        <div className="section landing-section">
          <Container>
            <Row
              style={{ fontSize: '18px', color: 'inherit', fontWeight: '500' }}>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center" style={{ paddingBottom: '2rem' }}>
                  Datenschutz
                </h2>
                <br />
                <br />
                <br />
                <Row>
                  {addCol('Verantwortlicher für die Datenverarbeitung')}
                  {addCol('Schuberth & Schuberth')}
                  {addCol('Versicherungsagentur')}
                  {addCol('Maximilian Schuberth')}
                  <Col md="12">
                    <i className="fa fa-map-pin"></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      Reichelstraße 7
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      95326 Kulmbach
                    </label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-phone"></i>
                    <a
                      href="tel:+49922195020"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 0
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-fax"></i>
                    <a
                      href="tel:+499221950225"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 25
                    </a>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-envelope"></i>
                    <a
                      href="mailto:schuberth@schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        color: 'inherit',
                        fontWeight: '500',
                      }}>
                      schuberth@schuberth-versicherungen.de
                    </a>
                  </Col>
                  <Col md="12">
                    <i className="fa fa-envelope" style={{ opacity: '0' }}></i>
                    <a
                      href="https://www.schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        fontWeight: '500',
                      }}>
                      www.schuberth-versicherungen.de
                    </a>
                  </Col>

                  <br />
                  <br />
                  <br />
                  {addPara(
                    'Verarbeitung personenbezogener Daten, Rechtsgrundlagen sowie Zwecke der Verwendung',
                    'Beim Aufrufen unserer Website www.schuberth-versicherungen.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:',
                    [
                      'IP-Adresse des anfragenden Rechners,',
                      'Datum und Uhrzeit des Zugriffs,',
                      'Name und URL der abgerufenen Datei,',
                      'Website, von der aus der Zugriff erfolgt (Referrer-URL),',
                      'verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.',
                    ],
                  )}
                  {addPara(
                    '',
                    'Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:',
                    [
                      'Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,',
                      'Gewährleistung einer komfortablen Nutzung unserer Website,',
                      'Auswertung der Systemsicherheit und -stabilität sowie',
                      'zu weiteren administrativen Zwecken.',
                    ],
                  )}
                  {addPara(
                    '',
                    'Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.',
                  )}
                  {addPara(
                    '',
                    'Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.',
                  )}
                  {addPara(
                    '',
                    'Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.',
                  )}
                  {addPara(
                    '',
                    'Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.',
                  )}
                  {addPara(
                    'Weitergabe von Daten',
                    'Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.',
                  )}
                  {addPara(
                    '',
                    'Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:',
                    [
                      'Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,',
                      'die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines Vertrages mit Ihnen oder zur Durchführung vorvertraglicher Maßnahmen, die auf Anfrage von ihnen erfolgen, erforderlich sind.',
                      'dies nach Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche unterliegt',
                      'dies nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,',
                    ],
                  )}
                  {addPara(
                    'Cookies',
                    'Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.',
                  )}
                  {addPara(
                    'Widerspruchsrecht',
                    'Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an schuberth@schuberth-versicherungen.de ',
                  )}
                  {addPara(
                    'Datensicherheit',
                    'Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. ',
                  )}
                  {addPara(
                    'Datenschutzerklärung',
                    'Die Formulare zur Datenschutzerklärung und Einwilligung zur Verarbeitung personenbezogener Daten können Sie jederzeit gerne bei uns anfordern.',
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Datenschutz;
