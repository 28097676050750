/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';

// pages
import LandingPage from 'views/LandingPage.js';
import Impressum from 'views/Impressum.js';
import Datenschutz from 'views/Datenschutz.js';
import DeinJob from 'views/DeinJob';
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/Impressum" render={props => <Impressum {...props} />} />
      <Route path="/Datenschutz" render={props => <Datenschutz {...props} />} />
      <Route path="/DeinJob" render={props => <DeinJob {...props} />} />
      <Route path="/" render={props => <LandingPage {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);
