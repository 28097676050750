import React from 'react';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

// core components
import LandingPageHeader from 'components/Headers/LandingPageHeader.js';
import FooterWhite from 'components/Footers/FooterWhite.js';

//helper
import { createTeamMember } from './OurTeam';
import Mail from '../components/Mail/index';

const addCol = content => {
  return (
    <Col md="12">
      <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
      <label style={{ paddingLeft: '1rem' }}>{content}</label>
    </Col>
  );
};

function DeinJob() {
  return (
    <>
      <div
        className="section section-white landing-section"
        style={{ backgroundColor: '#346494' }}>
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="/" style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={require('assets/img/logo.jpg')}
              style={{ width: '50%', margin: '0 auto' }}></img>
          </a>
        </Container>
      </div>
      <div className="wrapper">
        <div className="s-timeline">
          <div className="s-container left">
            <div className="s-content">
              <h2>Wir suchen Verstärkung für unser Team!</h2>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Das bieten wir</h2>
              <p>
                <ul>
                  <li>nette Kunden</li>
                  <li>flexible Arbeitszeit</li>
                  <li>Homeoffice</li>
                  <li>betriebliche Altersvorsorge</li>
                  <li>
                    heller Arbeitsplatz in Kulmbachs Stadtmitte mit
                    Siebträgerkaffeemaschine{' '}
                  </li>
                  <li>
                    persönliche Atmosphäre in unserem kleinen
                    Familienunternehmen
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Deine potentiellen Aufgabenbereiche</h2>
              <p>
                <ul>
                  <li>
                    Kundenbetreuung telefonisch und persönlich in der Agentur
                  </li>
                  <li>Büroorganisation</li>
                  <li>Kundendatenpflege</li>
                  <li>Angebotserstellung und Nachbearbeitung</li>
                  <li>Antragsaufnahme und -bearbeitung</li>
                  <li>Begleitung und Nachhaltung von Vertriebsaktionen</li>
                  <li>Schadenaufnahme, -bearbeitung und ggf. -regulierung </li>
                  <li>Pflege der Website und Ausbau der Social-Media-Kanäle</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Das bringst du mit</h2>
              <p>
                <ul>
                  <li>
                    du bist gern mit Kunden in Kontakt und kümmerst dich mit
                    Freude um deren Anliegen
                  </li>
                  <li>
                    du bist ein Organisationstalent und besitzt
                    Kommunikationsstärke
                  </li>
                  <li>du besitzt Teamgeist und Tatendrang</li>
                  <li>
                    du hast bereits Erfahrung in der Versicherungswirtschaft
                    gesammelt oder möchtest dich aus einem anderen
                    kaufmännischen Bereich umorientieren{' '}
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="s-container left">
            <div className="s-content">
              <h2>Melde dich bei uns, wenn wir dein Interesse geweckt haben</h2>
              <p>
                <ul>
                  <li>gerne telefonisch</li>
                  <li>per Mail</li>
                  <li>Kontaktformular</li>
                  <li>oder postalisch</li>
                  <li>und sende uns deinen Lebenslauf (per Mail)</li>
                  <Button
                    id="send-mail-btn"
                    className="btn btn-outline-neutral btn-lg btn btn-white"
                    size="lg"
                    style={{
                      color: 'inherit',
                      borderColor: 'inherit',
                      marginTop: '1rem',
                    }}
                    onClick={() =>
                      document.getElementById('contact-header').scrollIntoView()
                    }>
                    Zu den Kontaktdaten
                  </Button>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <Mail />
        <div className="section section-white landing-section">
          <Container>
            <Row style={{ fontSize: '18px', fontWeight: '500' }}>
              <Col className="ml-auto mr-auto" md="8">
                <h2
                  id="contact-header"
                  className="text-center"
                  style={{ paddingBottom: '2rem' }}>
                  Kontakt
                </h2>
                <Row>
                  {addCol('Schuberth & Schuberth')}
                  {addCol('Versicherungsagentur')}
                  {addCol('Maximilian Schuberth')}
                  <Col md="12">
                    <i className="fa fa-map-pin"></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      Reichelstraße 7
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-map-pin" style={{ opacity: '0' }}></i>
                    <label style={{ paddingLeft: '1rem' }}>
                      95326 Kulmbach
                    </label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-phone"></i>
                    <a
                      href="tel:+49922195020"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 0
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <i className="fa fa-fax"></i>
                    <a
                      href="tel:+499221950225"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      +49 9221 95 02 25
                    </a>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md="12">
                    <i className="fa fa-envelope"></i>
                    <a
                      href="mailto:schuberth@schuberth-versicherungen.de"
                      style={{
                        paddingLeft: '1rem',
                        color: '#66615b',
                        fontWeight: '500',
                      }}>
                      schuberth@schuberth-versicherungen.de
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default DeinJob;
