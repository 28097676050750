/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row } from 'reactstrap';

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a href="/" className="mr-1">
                    Startseite
                  </a>
                </li>
                <li>
                  <a href="/Impressum" className="mr-1">
                    Impressum
                  </a>
                </li>
                <li>
                  <a href="/Datenschutz" className="mr-1">
                    Datenschutz
                  </a>
                </li>
                <li>
                  <a href="/DeinJob" className="mr-1">
                    Dein Job
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}, Schuberth & Schuberth
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
