import React from 'react';

// reactstrap components
import { Card, CardBody, CardTitle, Col } from 'reactstrap';

export const createTeamMember = ({
  imgPath,
  name,
  position,
  description,
  subDescription,
  additionalBtn,
}) => {
  const addExtra = additionalBtn => {
    if (!additionalBtn) {
      return '';
    }
    return (
      <a href={additionalBtn.link} className={additionalBtn.className}>
        {additionalBtn.text}
      </a>
    );
  };

  return (
    <Col md="4">
      <Card className="card-profile card-plain">
        <div className="card-avatar">
          <a href="#pablo" onClick={e => e.preventDefault()}>
            <img alt="..." src={imgPath} />
          </a>
        </div>
        <CardBody>
          <a href="#pablo" onClick={e => e.preventDefault()}>
            <div className="author">
              <CardTitle tag="h4">{name}</CardTitle>
              <h6 className="card-category text-muted">{position}</h6>
            </div>
          </a>
          <p className="card-description text-center">{description}</p>
          <p className="card-description text-center">{subDescription}</p>
          {addExtra(additionalBtn)}
        </CardBody>
      </Card>
    </Col>
  );
};
