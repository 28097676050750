import React, { useState } from 'react';
import { Button, Form, Input, Container, Row, Col } from 'reactstrap';
import * as emailjs from 'emailjs-com';

const USER_ID = 'user_D4h8tzk9JdG0mygcgW2qF';
const SERVICE_ID = 'schuberth_insurance';
const TEMPLATE_ID = 'template_d7w3kl7';

export default function Mail() {
  const [mail, setMail] = useState({
    address: '',
    sender: '',
    message: '',
    success: false,
    error: false,
  });

  const onChangeInput = ({ target: { id, value } }) => {
    const newMailObj = {
      address: id == 'address' ? value : mail.address,
      message: id == 'message' ? value : mail.message,
      sender: id == 'sender' ? value : mail.sender,
      sucess: false,
    };
    setMail({ ...newMailObj });
  };

  const sendMail = () =>
  {
    const checkbox = document.querySelector('#datenschutz');
    if (!checkbox.checked)
    {
      alert("Bitte bestätigen Sie die Datenschutzbestimmungen.");
      return;
    }
    emailjs.init(USER_ID);
    emailjs.send(SERVICE_ID, TEMPLATE_ID, mail, USER_ID).then(
      response => {
        setMail({ ...mail, success: true, error: false });
      },
      err => {
        console.error(err);
        setMail({ ...mail, sucess: false, error: true });
      },
    );
  };

  return (
    <div className="section section-blue landing-section">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="text-center">Kontaktformular</h2>
            <Form className="contact-form">
              <Row>
                <Col md="6">
                  <label>Name</label>
                  <Input
                    id="sender"
                    placeholder="Name"
                    value={mail.sender}
                    onChange={onChangeInput}
                  />
                </Col>
                <Col md="6">
                  <label>Email</label>
                  <Input
                    id="address"
                    placeholder="Email"
                    value={mail.address}
                    onChange={onChangeInput}
                  />
                </Col>
              </Row>
              <label>Nachricht</label>
              <Input
                id="message"
                placeholder="Teilen Sie uns hier Ihr Anliegen mit..."
                type="textarea"
                rows="4"
                value={mail.message}
                onChange={onChangeInput}
              />

              <Row>
                <Col>
                  <p style={{color: 'white', marginTop: '1rem'}}>
                  Die von lhnen eingegebenen und an uns abgesendeten Daten werden nur zum Zweck der Bearbeitung lhres Anliegens verarbeitet. Mit der Absendung lhrer Anfrage erklären Sie lhre diesbezügliche Einwilligung gem. Art. 6 Abs.1 a DSGVO zur o.g. Verarbeitung lhrer Daten. Die Daten werden nach abgeschlossener Bearbeitung lhrer Anfrage gelöscht. lhre Einwilligung können Sie jederzeit auch für die Zukunft per E-Mail an schuberth@schuberth-versicherungen.de widerrufen.
                  </p>
                  <p>
                  <label for="datenschutz">
                      <input id="datenschutz" type="checkbox" style={{ marginRight: '0.5rem' }}></input>

                      Die
                      <a href="/Datenschutz"> Datenschutzbestimmungen </a>
                      habe ich zur Kenntnis genommen
                </label>
                  </p>
                  
                </Col>
              </Row>
              <Row>
                <Col className="offset-md-4" md="4">
                  <Button
                    id="send-mail-btn"
                    className="btn btn-outline-neutral btn-lg btn btn-white"
                    size="lg"
                    style={{
                      backgroundColor: mail.error
                        ? 'red'
                        : mail.success
                        ? 'green'
                        : 'inherit',
                    }}
                    onClick={sendMail}>
                    {mail.error
                      ? 'Fehler beim Versenden. Versuchen Sie es bitte erneut.'
                      : mail.success
                      ? 'Erfolgreich versendet.'
                      : 'Nachricht versenden'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
